.tokenInfo_data{
    font-size: calc(0.5vw + 8px);
    padding: 20px 40px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-around;
}
.tokenInfo_data div{
margin: 5px 0;
border-bottom: 1px solid #E3E3E3;
padding-bottom:7px;
display:flex;
justify-content: space-between;
}