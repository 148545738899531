.edit_token_popup{
    position:absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    opacity:0;
    display:flex;
    z-index:10;
    align-items: center;
    justify-content: center;
    animation-name: slide_popup;
    animation-duration:5s;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    animation-delay:0.5s;
}
.edit_token_success{
    background:#968ba7;
}
.edit_token_fail{
    background: rgb(192, 112, 112);
    
}
@keyframes slide_popup{
    0%{
        height: 0;
        opacity:0
    }
    5%{
        height: 5em;
        opacity:1;
    }
    95%{
        height: 5em;
        opacity:1;
    }
    100%{
        opacity:0;
        height: 0; 
    }
}