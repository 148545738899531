button{
    font-size: '3em';
    color: '#fff';
    padding: '20px 20px 20px 20px';
    text-transform: 'uppercase';
    border-radius: '8px';
    background-image: 'linear-gradient(to right, #47428F ,#A84583)';
}
button{
    transition: opacity 0.5s ease-in-out;
}
.token_button,
.token_button_disable{
    font-size:0.9em;
    color:#fff;
    padding:15px 65px;
    text-transform: uppercase;
    border-radius:8px;
    bottom:-80px;
    background-image: linear-gradient(to right, #47428F ,#A84583 );
}
.token_button_disable{
    opacity:0.4;
    cursor: not-allowed;
}