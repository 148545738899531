.link{
    display: flex;
    justify-content: baseline;
}
.arrow{
    margin-right: 25px;
}


.token_select{
    display:block;
    border:none;
    min-height: 40px;
    font-size:0.85em;
    font-weight:200;
    text-align:center;
    text-align-last:center;
    background-image: url("../../../icons/selectArrow.svg");
    background-repeat:no-repeat;
    background-size: 5%;
    background-position: 92%50%;
    background-color: rgba(255,255,255,0.1);
    width: 19%;
    height: 6vh;
    color: #fff;
    font-size: 1.5em;
    border-radius: 8px;
}
.token_select_option{
    background:#754F77;
}