.label{
    color:#98989B;
    display:block;
    text-transform:uppercase;
    font-size:0.6em;
    margin-bottom:10px;
    
}
.token_input{
    width:100%;
    border:none;
    border-bottom: 1px solid #EFEFEF;
    font-size:1em
}
.token_select{
    display:block;
    border:none;
    width:100%;
    height:50%;
    min-height: 40px;
    font-size:0.85em;
    font-weight:200;
    text-align-last:center;
    background-image: url("../../../icons/selectArrow.svg");
    background-repeat:no-repeat;
    background-size: 5%;
    background-position: 92%50%;
}
.error_message{
    position:absolute;
    font-size:0.8em;
    color:rgb(148, 69, 69);
}
