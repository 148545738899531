html{
    box-sizing: border-box;
}
*, *::after, *::before{
    box-sizing: inherit;
}
*{
    margin:0;
    padding:0;
}
body{
    font-family: 'LatoLatinWeb', sans-serif;
    overflow-x: hidden;
    overflow-y: auto;
}
a{
    color:inherit;
    text-decoration:none;
}
button,input,textarea,select, div{
    outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border: none;
}
button{
    cursor:pointer;
}
