.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

.loader {
  
  border-top: 1.1em solid rgba(143, 75, 131, 0.2);
  border-right: 1.1em solid rgba(122, 66, 113, 0.2);
  border-bottom: 1.1em solid rgba(208, 140, 197, 0.2);
  border-left: 1.1em solid rgb(121, 72, 141);
  
  margin:  25% auto;
  font-size: 8px;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: spin 1.1s infinite linear;
  animation: spin 1.1s infinite linear;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
