html{
    box-sizing: border-box;
}
*, *::after, *::before{
    box-sizing: inherit;
}
*{
    margin:0;
    padding:0;
}
body{
    font-family: 'LatoLatinWeb', sans-serif;
    overflow-x: hidden;
    overflow-y: auto;
}
a{
    color:inherit;
    text-decoration:none;
}
button,input,textarea,select, div{
    outline: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  border: none;
}
button{
    cursor:pointer;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

.loader {
  
  border-top: 1.1em solid rgba(143, 75, 131, 0.2);
  border-right: 1.1em solid rgba(122, 66, 113, 0.2);
  border-bottom: 1.1em solid rgba(208, 140, 197, 0.2);
  border-left: 1.1em solid rgb(121, 72, 141);
  
  margin:  25% auto;
  font-size: 8px;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: spin 1.1s infinite linear;
  animation: spin 1.1s infinite linear;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.style_modal_background__3UObs{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    background-image: linear-gradient(rgba(85,77,172,0.6) ,rgba(224,90,219,0.35));
}
.style_modal__kGP8w{
    position: relative;
    height: 100%;
    padding: 10% 24%;
    text-align: center;
    font-size: calc(0.5vw + 9px);
    color: #4B4B4B;
}
.style_close__8aU71{
    position: absolute;
    top: 30px;
    right: 30px;
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
}
.style_notification__2NmaU{
    font-weight: 200;
    font-size: 1.6em;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
}
.style_header__3uOWH{
    font-weight: 200;
    font-size: 1.1em;
}
.style_instruction__14Hyv{
    margin-top: 30px;
    font-size: 0.8em;
}

.style_label__3Vubv{
    color:#98989B;
    display:block;
    text-transform:uppercase;
    font-size:0.6em;
    margin-bottom:10px;
    
}
.style_file_label__2nSq_{
  display:block;
  width:80%;
  height:80%;
  border:1px solid #D9D9D9;
  border-radius:8px;
  position:relative;
  cursor:pointer;
  min-width:220px;
  min-height:220px;
}
.style_token_form__1D-tE{
    padding:50px;
    display:grid;
    grid-template-columns: 10% 10% 1fr 1fr;
    grid-template-rows: repeat(4,18%);
    grid-column-gap:3vw;
    grid-row-gap: 4vh;
    font-size:calc(0.5vw + 8px);
    letter-spacing:0.3px;
}
button{
    transition: opacity 0.5s ease-in-out;
}
.style_token_button__2deNP,
.style_token_button_disable__2YSlZ{
    font-size:0.9em;
    color:#fff;
    padding:15px 65px;
    text-transform: uppercase;
    border-radius:8px;
    position:absolute;
    bottom:-80px;
    left:50%;
    -webkit-transform:translateX(-50%);
            transform:translateX(-50%);
    background-image: linear-gradient(to right, #47428F ,#A84583 );
}
.style_token_button_disable__2YSlZ{
    opacity:0.4;
    cursor: not-allowed;
}
.style_token_input__jGNmV,
.style_token_textArea__QbQsW{
    width:100%;
    border:none;
    border-bottom: 1px solid #EFEFEF;
    font-size:1em
}
.style_token_textArea__QbQsW{
    height:76%;
    resize: none;
}
.style_token_select__1LvXr{
    display:block;
    border:none;
    width:100%;
    height:50%;
    min-height: 40px;
    font-size:0.85em;
    font-weight:200;
    padding-left: 43%;
    background-image: url(/static/media/selectArrow.7596da17.svg);
    background-repeat:no-repeat;
    background-size: 5%;
    background-position: 92%50%;
}
.style_error_message__3VT6P{
    position:absolute;
    font-size:0.8em;
    color:rgb(148, 69, 69);
    

}

.style_link__3t53Z{
    display: flex;
    justify-content: baseline;
}
.style_arrow__HUSMW{
    margin-right: 25px;
}
.style_modal_background__YpIX-{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    background-image: linear-gradient(rgba(85,77,172,0.6) ,rgba(224,90,219,0.35));
}
.style_modal__G5Kj6{
    height: 100%;
    padding: 10% 20%;
    text-align: center;
    font-size: calc(0.5vw + 9px);
    color: #4B4B4B;
}
.style_close__2onMX{
    position:absolute;
    top: 30px;
    right: 30px;
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
}
.style_notification__2-hxU{
    font-weight: 200;
    line-height: 1.6;
    font-size: 1.6em;
    position: absolute;
    width:80%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
}
.style_txt_first__3Ediq{
    font-weight: 200;
    font-size: 1.1em;
}
.style_txt_content__2B6qD{
    margin-top: 30px;
    font-size: 0.8em;
    line-height: 1.5;
}
.style_modal_background__1G7RU{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    background-image: linear-gradient(rgba(85,77,172,0.6) ,rgba(224,90,219,0.35));
}
.style_modal__1f_rw{
    height: 100%;
    padding: 10% 20%;
    text-align: center;
    font-size: calc(0.5vw + 9px);
    color: #4B4B4B;
}
.style_close__3-gfP{
    position: absolute;
    top: 30px;
    right: 30px;
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
}
.style_notification__2acAR{
    line-height: 1.5;
    font-size: 1.1em;
}
.style_label__KkyHv{
    color:#98989B;
    display:block;
    text-transform:uppercase;
    font-size:0.6em;
    margin-bottom:10px;
    
}
.style_token_input__xSFJJ{
    width:100%;
    border:none;
    border-bottom: 1px solid #EFEFEF;
    font-size:1em
}
.style_token_select__30uev{
    display:block;
    border:none;
    width:100%;
    height:50%;
    min-height: 40px;
    font-size:0.85em;
    font-weight:200;
    text-align-last:center;
    background-image: url(/static/media/selectArrow.7596da17.svg);
    background-repeat:no-repeat;
    background-size: 5%;
    background-position: 92%50%;
}
.style_error_message__1Fj9q{
    position:absolute;
    font-size:0.8em;
    color:rgb(148, 69, 69);
}

.style_tokenInfo_data__3j40f{
          font-size: calc(0.5vw + 8px);
          padding: 20px 40px;
          display: flex;
          flex-flow: column nowrap;
          justify-content: space-around;
}
.style_tokenInfo_data__3j40f div{
    margin: 5px 0;
    border-bottom: 1px solid #E3E3E3;
    padding-bottom:7px;
    display:flex;
    justify-content: space-between;
}
.style_tokenInfo_inner__2bDjA{
    display: flex;
    align-items: center;
    justify-content:left;
}
.style_token_img__20T6x{
    border-radius:50%;
    width: 7vh;
    height: 7vh;
    box-shadow: 3px 2px 10px 1px rgba(0,0,0,0.32);
    margin-right: 20px;
}
.style_text_box__1tNhD{
    padding:0 40px;
}
.style_txt__rFy_0{
    margin: 20px 0px;
}

.style_token_select__OUpP_{
    display:block;
    border:none;
    min-height: 40px;
    font-size:0.85em;
    font-weight:200;
    text-align:center;
    text-align-last:center;
    background-image: url(/static/media/selectArrow.7596da17.svg);
    background-repeat:no-repeat;
    background-size: 5%;
    background-position: 92%50%;
    background-color: rgba(255,255,255,0.1);
    width: 19%;
    height: 6vh;
    color: #fff;
    font-size: 1.5em;
    border-radius: 8px;
}
.style_token_select_option__2OfnB{
    background:#754F77;
}

.edit_token_popup{
    position:absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    opacity:0;
    display:flex;
    z-index:10;
    align-items: center;
    justify-content: center;
    -webkit-animation-name: slide_popup;
            animation-name: slide_popup;
    -webkit-animation-duration:5s;
            animation-duration:5s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-delay:0.5s;
            animation-delay:0.5s;
}
.edit_token_success{
    background:#968ba7;
}
.edit_token_fail{
    background: rgb(192, 112, 112);
    
}
@-webkit-keyframes slide_popup{
    0%{
        height: 0;
        opacity:0
    }
    5%{
        height: 5em;
        opacity:1;
    }
    95%{
        height: 5em;
        opacity:1;
    }
    100%{
        opacity:0;
        height: 0; 
    }
}
@keyframes slide_popup{
    0%{
        height: 0;
        opacity:0
    }
    5%{
        height: 5em;
        opacity:1;
    }
    95%{
        height: 5em;
        opacity:1;
    }
    100%{
        opacity:0;
        height: 0; 
    }
}
.style_label__1mtn0{
    color:#98989B;
    display:block;
    text-transform:uppercase;
    font-size:0.6em;
    margin-bottom:10px;
    
}
.style_file_label__1bR1R{
  display:block;
  width:80%;
  height:80%;
  border:1px solid #D9D9D9;
  border-radius:8px;
  position:relative;
  cursor:pointer;
  min-width:220px;
  min-height:220px;
}
.style_token_form__2tgR1{
    padding:50px;
    display:grid;
    grid-template-columns: 10% 10% 1fr 1fr;
    grid-template-rows: repeat(4,18%);
    grid-column-gap:3vw;
    grid-row-gap: 4vh;
    font-size:calc(0.5vw + 8px);
    letter-spacing:0.3px;
}
button{
    transition: opacity 0.5s ease-in-out;
}
.style_token_button__28m6k,
.style_token_button_disable__1rs7f{
    font-size:0.9em;
    color:#fff;
    padding:15px 65px;
    text-transform: uppercase;
    border-radius:8px;
    position:absolute;
    bottom:-80px;
    left:50%;
    -webkit-transform:translateX(-50%);
            transform:translateX(-50%);
    background-image: linear-gradient(to right, #47428F ,#A84583 );
}
.style_token_button_disable__1rs7f{
    opacity:0.4;
    cursor: not-allowed;
}
.style_token_input__13UyO,
.style_token_textArea__2o5Ig{
    width:100%;
    border:none;
    border-bottom: 1px solid #EFEFEF;
    font-size:1em
}
.style_token_textArea__2o5Ig{
    height:76%;
    resize: none;
}
.style_token_select__2lYNg{
    display:block;
    border:none;
    width:100%;
    height:50%;
    min-height: 40px;
    font-size:0.85em;
    font-weight:200;
    padding-left: 43%;
    background-image: url(/static/media/selectArrow.7596da17.svg);
    background-repeat:no-repeat;
    background-size: 5%;
    background-position: 92%50%;
}
.style_error_message__1Z9Cu{
    position:absolute;
    font-size:0.8em;
    color:rgb(148, 69, 69);
    

}

.style_tokenInfo_data__so-v5{
    font-size: calc(0.5vw + 8px);
    padding: 20px 40px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-around;
}
.style_tokenInfo_data__so-v5 div{
margin: 5px 0;
border-bottom: 1px solid #E3E3E3;
padding-bottom:7px;
display:flex;
justify-content: space-between;
}
.style_link__LHRd1{
    display: flex;
    justify-content: baseline;
}
.style_arrow__1cBYm{
    margin-right: 25px;
}


.style_token_select__2wAw0{
    display:block;
    border:none;
    min-height: 40px;
    font-size:0.85em;
    font-weight:200;
    text-align:center;
    text-align-last:center;
    background-image: url(/static/media/selectArrow.7596da17.svg);
    background-repeat:no-repeat;
    background-size: 5%;
    background-position: 92%50%;
    background-color: rgba(255,255,255,0.1);
    width: 19%;
    height: 6vh;
    color: #fff;
    font-size: 1.5em;
    border-radius: 8px;
}
.style_token_select_option__1tOaJ{
    background:#754F77;
}
button{
    font-size: '3em';
    color: '#fff';
    padding: '20px 20px 20px 20px';
    text-transform: 'uppercase';
    border-radius: '8px';
    background-image: 'linear-gradient(to right, #47428F ,#A84583)';
}
button{
    transition: opacity 0.5s ease-in-out;
}
.style_token_button__1PTrH,
.style_token_button_disable__PlwZt{
    font-size:0.9em;
    color:#fff;
    padding:15px 65px;
    text-transform: uppercase;
    border-radius:8px;
    bottom:-80px;
    background-image: linear-gradient(to right, #47428F ,#A84583 );
}
.style_token_button_disable__PlwZt{
    opacity:0.4;
    cursor: not-allowed;
}
