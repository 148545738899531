.label{
    color:#98989B;
    display:block;
    text-transform:uppercase;
    font-size:0.6em;
    margin-bottom:10px;
    
}
.file_label{
  display:block;
  width:80%;
  height:80%;
  border:1px solid #D9D9D9;
  border-radius:8px;
  position:relative;
  cursor:pointer;
  min-width:220px;
  min-height:220px;
}
.token_form{
    padding:50px;
    display:grid;
    grid-template-columns: 10% 10% 1fr 1fr;
    grid-template-rows: repeat(4,18%);
    grid-column-gap:3vw;
    grid-row-gap: 4vh;
    font-size:calc(0.5vw + 8px);
    letter-spacing:0.3px;
}
button{
    transition: opacity 0.5s ease-in-out;
}
.token_button,
.token_button_disable{
    font-size:0.9em;
    color:#fff;
    padding:15px 65px;
    text-transform: uppercase;
    border-radius:8px;
    position:absolute;
    bottom:-80px;
    left:50%;
    transform:translateX(-50%);
    background-image: linear-gradient(to right, #47428F ,#A84583 );
}
.token_button_disable{
    opacity:0.4;
    cursor: not-allowed;
}
.token_input,
.token_textArea{
    width:100%;
    border:none;
    border-bottom: 1px solid #EFEFEF;
    font-size:1em
}
.token_textArea{
    height:76%;
    resize: none;
}
.token_select{
    display:block;
    border:none;
    width:100%;
    height:50%;
    min-height: 40px;
    font-size:0.85em;
    font-weight:200;
    padding-left: 43%;
    background-image: url("../../../icons/selectArrow.svg");
    background-repeat:no-repeat;
    background-size: 5%;
    background-position: 92%50%;
}
.error_message{
    position:absolute;
    font-size:0.8em;
    color:rgb(148, 69, 69);
    

}
