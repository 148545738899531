.modal_background{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    background-image: linear-gradient(rgba(85,77,172,0.6) ,rgba(224,90,219,0.35));
}
.modal{
    height: 100%;
    padding: 10% 20%;
    text-align: center;
    font-size: calc(0.5vw + 9px);
    color: #4B4B4B;
}
.close{
    position:absolute;
    top: 30px;
    right: 30px;
    transform: scale(0.6);
}
.notification{
    font-weight: 200;
    line-height: 1.6;
    font-size: 1.6em;
    position: absolute;
    width:80%;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.txt_first{
    font-weight: 200;
    font-size: 1.1em;
}
.txt_content{
    margin-top: 30px;
    font-size: 0.8em;
    line-height: 1.5;
}