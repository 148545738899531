.tokenInfo_data{
          font-size: calc(0.5vw + 8px);
          padding: 20px 40px;
          display: flex;
          flex-flow: column nowrap;
          justify-content: space-around;
}
.tokenInfo_data div{
    margin: 5px 0;
    border-bottom: 1px solid #E3E3E3;
    padding-bottom:7px;
    display:flex;
    justify-content: space-between;
}
.tokenInfo_inner{
    display: flex;
    align-items: center;
    justify-content:left;
}
.token_img{
    border-radius:50%;
    width: 7vh;
    height: 7vh;
    box-shadow: 3px 2px 10px 1px rgba(0,0,0,0.32);
    margin-right: 20px;
}
.text_box{
    padding:0 40px;
}
.txt{
    margin: 20px 0px;
}